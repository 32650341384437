<template>
  <v-app class="form-container">
    <TextBox
      :cefrLevel="payload.cefr_level"
      v-model="title"
      label="Title"
    />

    <label class="item-label mt-6">Speaking Text</label>
    <Textarea
      :rows="4"
      :cefrLevel="payload.cefr_level"
      v-model="speaking_text"
    />

    <AudioInput
      label="Assessment Audio"
      :status="status"
      :audioFilename.sync="audioFilename"
      :audioFileLocation.sync="audioFileLocation"
    />
  </v-app>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import { v4 as uuidv4 } from "uuid"
import TextBox from "@/components/item/TextBox"
import Textarea from "@/components/item/Textarea"
import AudioInput from "@/components/item/AudioInput"
export default {
  components: {
    TextBox,
    Textarea,
    AudioInput,
  },
  data: () => ({
    title: "",
    speaking_text: "",
    static_text: "Listen. Type the sentence that you hear",

    // Note: The audio file is in the data.assets{} payload
    audioFilename: "",
    audioFileLocation: "",
  }),
  computed: {
    ...mapState("item", ["payload", "isUpdate", "status"]),
    ...mapGetters("account", ["user"]),
  },
  methods: {
    ...mapActions("item", ["uploadFile"]),
    onSubmit() {
      const data = {
        template_type: "DICTATION",
        title: this.title,
        speaking_text: this.speaking_text,
        static_text: this.static_text,
      }

      data.assets = {
        audio: [{
          file_name: this.audioFilename,
          file_location: this.audioFileLocation,
        }],
      }

      if (!this.isUpdate) {
        /**
         * The questions[] array here is hard-coded because
         *  a) it is designed to be static,
         *  b) the API requires it.
         */
        data.questions = [
          {
            question_id: uuidv4(),
            question: this.static_text,
            solution: [],
            metadatas: { instructions: [''] },
          },
        ]
      }
      this.$store.commit('item/setPayload', data)
    }
  },
  beforeMount() {
    if (this.isUpdate) {
      const { title, speaking_text } = this.payload
      this.title = title
      this.speaking_text = speaking_text

      // Assessment audio
      const { file_location: audioFileLocation, file_name: audioFilename } = this.payload.assets.audio[0]
      this.audioFileLocation = audioFileLocation
      this.audioFilename = audioFilename
    }
  },
}
</script>

<style lang="scss" scoped>
.form-container {
  max-width: 550pt;
}
label {
  color: #264d98;
  font-size: 1em;
  margin-bottom: 10pt 0pt;
  &.ghost {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.4);
    background-color: rgba(230, 230, 230, 0.4);
    border: none;
  }
}
::v-deep {
  .v-application--wrap {
    min-height: auto;
  }
}
</style>
